.loginbtn {
  background: #e25b30 0% 0% no-repeat padding-box !important;
  border-radius: 38px;
  padding: 5px 20px;
  border: 1px solid #e25b30;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000029;
  height: 42px;
  font-size: 14px;
  font-weight: 500;
}
